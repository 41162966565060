import {firebase} from '../utilities'
import {resourceType} from '../validators'
import DB from '../DAL/DB'
import {auth} from '../Auth'

export const validators = resourceType['user']

const users = new DB.Users()
const organizations = new DB.Organizations()

export async function usersOne(ff, logger) {
  const {
    ok,
    data: [user],
  } = await users.get(undefined, {filters: [['email', '==', ff.email]]})

  if (user) {
    logger.error(`A user with the email address (${ff.email}) already exists. User creation failed`)
    return
  }
  // validate that fields exist
  let errors = []
  for (let field of ['email', 'type', 'name']) {
    if (!ff[field]) {
      logger.error(`missing ${field} field, user cannot be created`)
      errors.push(field)
    }
  }
  if (errors.length) throw new Error(`field(s) ${JSON.stringify(errors)} failed validation`)

  if (ff.type === 'field-user' && !ff.address) {
    logger.event('No address provided; this field user will not appear on the scheduling map')
    throw new Error(`Failed validation because user type is field user and address does not exist`)
  }

  const timestamp = firebase.firestore.Timestamp.now()
  if (!ff.id) ff.id = users.makeId()

  if (ff['type'] === 'client') {
    ff.organization = await createOrganization({ ...ff, name: ff.company }, timestamp)
  } else {
    ff['organization'] = {...auth.sunkaizenUser.organization}
  }

  try {
    await users.update(ff.id, null, {...ff, updated: timestamp, created: ff.created ? ff.created : timestamp})
    logger.event(`Successfully ${ff.id ? 'modified user' : 'created new user'} ${ff.email}`)
  } catch (e) {
    logger.error('An error occurred trying to save this user; user was not successfully created')
    throw e
  }
}

async function createOrganization({id, name = null, email, phone = null, address = null}, timestamp) {
  await organizations.update(id, null, {id, name, email, created: timestamp, modified: timestamp, phone, address})

  return {id, name, email}
}
