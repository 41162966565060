import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ActionsRender from './ActionsRender';
import DB from '../DAL/DB';
import save from './save';

const ActionsReq = () => {
  const { actionList: actionListDB } = useSelector((state) => state.actionsState);
  const { saveEnterprisePDFOptionChecked } = useSelector((state) => state.form);
  const [actionList, setActionList] = useState(actionListDB || []);
  const dispatch = useDispatch();
  const form = useSelector((state) => state.form);
  const formAction = new DB.FormAction(form.id);
  const [enableSave, setEnableSave] = useState(false);

  const actionObj = {
    id: '',
    strategy: '',
    criticality: '',
    cost: '',
    difficulty: '',
    icon: '',
    actionDescription: '',
    referenceInformation: '',
    tags: [],
  };

  useEffect(() => {
    dispatch({ type: 'saveAction', value: actionList });
  }, [actionList, dispatch]);

  const handleDeleteAction = useCallback((action) => {
    setActionList((prevActions) => {
      const editActions = [...prevActions];
      const editedRec = editActions.find((x) => x.id === action.id);
      if (editedRec) {
        editedRec.isDelete = 'confirmation';
      }
      return editActions;
    });
  }, []);

  const handleDeleteActionCancel = useCallback((action) => {
    setActionList((prevActions) => {
      const editActions = [...prevActions];
      const editedRec = editActions.find((x) => x.isDelete === 'confirmation');
      if (editedRec) {
        editedRec.isDelete = false;
      }
      return editActions;
    });
  }, []);

  const handleDeleteActionConfirmation = useCallback((action) => {
    setActionList((prevActions) => {
      const editActions = [...prevActions];
      const editedRec = editActions.find((x) => x.isDelete === 'confirmation');
      if (editedRec) {
        editedRec.isDelete = true;
      }
      return editActions;
    });
  }, []);

  const handleAddActions = useCallback(() => {
    setActionList((prevActions) => {
      const actionListUpdated = [...prevActions];
      actionObj.id = formAction.makeId();
      actionListUpdated.push(actionObj);
      return actionListUpdated;
    });
  }, [formAction]);

  const handleUpdateAction = useCallback((updatedActionObj) => {
    setActionList((prevActions) => {
      const actionListUpdated = [...prevActions];
      const objIndex = actionListUpdated.findIndex((x) => x.id === updatedActionObj.id);
      if (objIndex !== -1) {
        actionListUpdated[objIndex] = updatedActionObj;
      }
      return actionListUpdated;
    });
  }, []);

  const handleSaveReduxState = useCallback((actionListUpdated) => {
    const updatedList = actionListUpdated.map((rec, index) => {
      rec.actionIndex = rec.isDelete ? 0 : index + 1;
      return rec;
    });

    dispatch({ type: 'saveAction', value: updatedList });
    setEnableSave(true);
  }, [dispatch]);

  const handleSaveActions = useCallback(() => {
    save();
    setEnableSave(false);
  }, []);

  const isSaveEnabled = useCallback(() => {
    return (actionList || []).filter((x) => !x.isDelete).some((actionObj) => {
      return ['actionDescription', 'strategy'].some((prop) => actionObj[prop] === '');
    });
  }, [actionList]);

  const renderActions = useCallback(() => {
    return (
      <ActionsRender
        actionList={actionList}
        handleDeleteAction={handleDeleteAction}
        handleDeleteActionCancel={handleDeleteActionCancel}
        handleDeleteActionConfirmation={handleDeleteActionConfirmation}
        handleUpdateAction={handleUpdateAction}
      />
    );
  }, [actionList, handleDeleteAction, handleDeleteActionCancel, handleDeleteActionConfirmation, handleUpdateAction]);

  const renderActionButtons = useCallback(() => {
    return (
      <React.Fragment>
        <Button className="formAction" title="add-tag" icon="plus" onClick={handleAddActions} />
        <Button
          ghost={false}
          className="thick saveActionbtn"
          type={`primary`}
          size="large"
          disabled={actionList.length === 0 || !enableSave || isSaveEnabled()}
          onClick={handleSaveActions}
        >
          Save
        </Button>
      </React.Fragment>
    );
  }, [actionList.length, enableSave, handleAddActions, handleSaveActions, isSaveEnabled]);

  const handleGeneratePDFSelection = useCallback((e) => {
    dispatch({ type: 'saveEnterprisePDFOption', value: e.target.checked });
    setEnableSave(true);
  }, [dispatch]);

  return (
    <div>
      {renderActionButtons()}
      <div>
        <div className="generatePDF">
          <input
            type="checkbox"
            key={`chk-${saveEnterprisePDFOptionChecked}`}
            defaultChecked={saveEnterprisePDFOptionChecked}
            onChange={handleGeneratePDFSelection}
          />
          <label className="generatePDFLabel">Generate Action Report for all Projects using this Form</label>
        </div>
        <h3> Available Actions</h3>{' '}
      </div>
      <React.Fragment key={`action-${actionList.length}`}>{renderActions()}</React.Fragment>
    </div>
  );
};

export { ActionsReq };
