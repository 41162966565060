import React, {useEffect, useState} from 'react'
import {Button, Modal} from 'antd'
import Provider from './Provider'

export default function AddProjectButton({id, size, title, success = [], className, style, type}) {
  const [done, setDone] = useState(false)
  const [show, setShow] = useState(null)

  const [s, setS] = useState([])

  useEffect(function () {
    setS([
      () => {
        setShow(false)
        console.log('closing modal')
      },
    ])
  }, [])

  return (
    <div>
      <Button {...{size, className, style, type}} onClick={() => setShow(true)}>
        {title ? title : 'Add Project'}
      </Button>
      <Modal
        width={'80%'}
        destroyOnClose={true}
        onCancel={() => {
          setDone('clear')
          setShow(false)
        }}
        onOk={() => {
          setDone('done')
        }}
        visible={show}
      >
        <Provider id={id} done={done} setDone={setDone} success={[...success, ...s]} />
      </Modal>
    </div>
  )
}
