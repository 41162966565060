import React, {useState, useEffect} from 'react'
import {auth} from '../Auth'
import {Button} from 'antd'
import DB from '../DAL/DB'
import {loggerEffect} from '../effects'
import {firebase} from '../utilities'
import SiteNote from './SiteNote'

const projects = new DB.Projects()

// deleteSiteNote, addSiteNote, changeSiteNote,
export default function SiteNotes({resource}) {
  const [logger, setLogger] = useState(null)
  const [notes, setNotes] = useState([])

  useEffect(() => {
    if (resource.siteNotes) setNotes(resource.siteNotes)
  }, [resource.siteNotes])
  useEffect(loggerEffect(setLogger), [])

  async function addSiteNote() {
    const {
      currentUser: {displayName, uid: authorId},
      sunkaizenUser: {name},
    } = auth
    const author = name || displayName

    const newSiteNote = {created: firebase.firestore.Timestamp.now(), note: ``, author, authorId}

    try {
      
      await projects.updatebatch(resource.id, null, {updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name, siteNotes: [...(resource.siteNotes || []), newSiteNote]})
      logger.event(`${author} added a site note`)
    } catch (e) {
      console.log(`error adding a site note`, e)
    }
  }

  function deleteSiteNote(i) {
    const siteNotes = [...resource.siteNotes]
    siteNotes.splice(i, 1)
    projects.updatebatch(resource.id, null, {updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name, siteNotes})
  }

  function changeSiteNote(e, i) {
    const siteNotes = [...resource.siteNotes]
    const {note, authorId, created, contactAttempted} = notes[i]

    const {
      currentUser: {displayName},
      sunkaizenUser: {name},
    } = auth
    const author = name || displayName

    siteNotes[i] = {note, authorId, created, author, contactAttempted: contactAttempted || false}

    if (e.target.type === 'checkbox') {
      logger.event(`${author} attempted to contact the site`)
      siteNotes[i].contactAttempted = e.target.checked || false
    }

    projects.updatebatch(resource.id, null, {updatedBy: auth?.sunkaizenUser?.id, updatedByName: auth?.sunkaizenUser?.name, siteNotes})
  }

  return (
    <div className="flex-column align-start background-white padding-2 margin-bottom-1" style={{fontAlign: `left`}}>
      <h4>Notes</h4>
      <div className="full-width flex-column align-start padding-1" style={{background: `rgba(249, 250, 251, 0.7)`}}>
        <div className="full-width" style={{padding: `0 0 0 0.5rem`}}>
          {console.log(notes)}
          {notes.map((note, i) => (
            <SiteNote
              i={i}
              deleteSiteNote={deleteSiteNote}
              changeSiteNote={changeSiteNote}
              setNotes={setNotes}
              notes={notes}
              {...note}
            />
          ))}
        </div>
        <Button onClick={addSiteNote}>Add Note</Button>
      </div>
    </div>
  )
}
