import React, {useContext, useEffect} from 'react'
import {ReducerContext} from '../reducer'
import {pushData} from '../utilities'
import moment from 'moment'
import {TimePicker} from 'antd'
import getDisabled from './getDisabled'

const allHours = []
for (let i = 0; i < 24; i++) {
  allHours.push(i)
}

export default function TimeChooser({inspector = {}}) {
  const {state, dispatch} = useContext(ReducerContext)
  const {
    date,
    time,
    duration,
    calendar,
    project: {restrict_schedule},
  } = state
  const push = pushData({state, dispatch})

  // is an hour disabled if a project is scheduled in it?
  const [disabledHours, disabledMinutes] = getDisabled(date, calendar, inspector.availability)

  useEffect(function () {
    if (duration) push({time, duration})
    else push({time})
  }, [])

  useEffect(
    function () {
      if (time && !duration) push({duration: moment().startOf('day')})
    },
    [time]
  )

  let excludedDurationHours
  if (time) {
    const h = time.hours()
    const de = disabledHours()
    const durationEnd = de.filter((e) => e > h).sort((a, b) => (a > b ? 1 : -1))
    durationEnd.push(24)

    const stop = durationEnd[0] - h
    excludedDurationHours = allHours.filter((h) => h >= stop)
  }

  return (
    <div>
      <div className="bold padding-top-1">Time</div>
      <TimePicker
        value={time}
        disabledHours={restrict_schedule ? disabledHours : undefined}
        disabledMinutes={restrict_schedule ? disabledMinutes : undefined}
        format="h:mm a"
        onChange={(time) => {
          push({time})
        }}
        minuteStep={5}
      />
      <div className="bold padding-top-1">Duration</div>
      {time && (
        <TimePicker
          disabledHours={() => (restrict_schedule ? excludedDurationHours : undefined)}
          // disabledMinutes={disabledMinutes}
          format="HH:mm"
          placeholder="Duration"
          minuteStep={15}
          onChange={(duration) => {
            if (!duration) {
              push({duration: null})
              return
            }
            push({duration: moment({hours: duration.hours(), minutes: duration.minutes()})})
          }}
          value={duration}
        />
      )}
    </div>
  )
}
