import React, {useEffect, useState} from 'react'
import {Select} from 'antd'
import { db } from '../../../../utilities'
import { set } from 'lodash'

export default function AssignedTo({assignedTo, change, ticket, project}) {

  //console.log('xxx',assignedTo, ticket, project)

  const [users, setUsers] = useState([])
  
  useEffect(() => {
    if (Object.keys(project).length === 0) return 

    let rawUsers
    if (!project) {
      rawUsers = Object.values(ticket.watchersMap)
    } else {
      rawUsers = [
        {...project.installer, type: 'Installer'},
        {...project.client, type: 'Client'},
        {...project.reviewer, type: 'Quality Manager'},
        {...(project.inspection || {}).inspector, type: 'Inspector'},
      ]
    }

    const ids = rawUsers.map(({id}) => id)
    //const unique = [...new Set(ids)]; //not required
    db.collection('users').where('id', 'in', ids).get().then((querySnapshot) => {
        const disabledIds = [];
        querySnapshot.forEach((doc) => {
            const { disabled = false } = doc.data();
            if (disabled) {
                disabledIds.push(doc.id);
            }
        });
        const tmp = rawUsers.filter(user => !disabledIds.includes(user.id)).map(each => {
          return { id: each.id, name: each.name, phone: each?.phone || null, type: each.type, sumType: each.type  }
        })
        console.log('tmp',tmp)

        const mergedData = tmp.reduce((acc, current) => {
          const existing = acc.find(item => item.id === current.id);
          if (existing) {
              existing.sumType = `${existing.type} / ${current.type}`;
          } else {
              acc.push({ ...current });
          }
          return acc;
        }, []);

        setUsers(mergedData);
    }).catch(error => {
        console.error("Error fetching users: ", error);
    });
  },[ticket, project])
 
  useEffect(()=> {
    console.log('users',users)
  },[users])


  return (
    <div>
      <label>Assigned To</label>
      <Select
        className="input-field"
        size="large"
        value={assignedTo}
        placeholder="Select Assignee or enter Email"
        onChange={(e) => change('assignee', e)}
        style={{width: `100%`}}
      >
        {users
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({name, id, type, sumType }) => (
            <Select.Option key={id} value={id}>{`${name} (${sumType})`}</Select.Option>
          ))}
        <Select.Option key={`Other`} value={`Other`}>
          Other
        </Select.Option>
      </Select>
    </div>
  )
}
