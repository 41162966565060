import rootReducer from './reducer'
import {createStore} from 'redux'
import {auth} from '../Auth'
import DB from '../DAL/DB'

export const store = createStore(rootReducer)

export function makeFailuresBySection(failures) {
  const state = store.getState()

  const {sections} = state.project.intake || {}

  const reversed = {}

  for (let i in sections) {
    for (let f in sections[i].fields) {
      reversed[f] = i
    }
  }

  console.log(reversed)

  for (let j in failures) {
    const f = failures[j]
    f.sectionId = reversed[f.id]
    f.sectionTitle = sections[f.sectionId].title
  }

  const bySection = {}
  for (let k in failures) {
    const failure = failures[k]
    const section = sections[failure.sectionId]
    if (bySection[section.title]) bySection[section.title][failure.id] = failure
    else {
      bySection[section.title] = {}
      bySection[section.title][failure.id] = failure
    }
  }

  return bySection
}

export function makeFailures(project) {
  if (!project.intakeResult) {
    console.log('project was not scored')
    return []
  }

  if (!project.intakeResult.rawData) {
    console.log('project was scored, but has no raw scoring data')
    return []
  }

  const failures = {}
  for (let i in project.intakeResult.rawData.scored) {
    const q = project.intakeResult.rawData.scored[i]
    if (q.score === 'Fail') failures[q.id] = q
  }

  return failures
}

const tickets = new DB.Tickets()

export async function makeTicket() {
  const state = store.getState()
  let {project = {}, ticket, selectedFailure: failure} = state
  if (!failure) failure = ticket.failure

  let watcherMap = null

  if (!ticket.watcherMap && !project.id && ticket.project) {
    const projects = new DB.Projects()
    const {
      data: [p],
    } = await projects.get(ticket.project)
    project = p

    delete ticket.watcherMap
  }

  if (project.id) watcherMap = getWatchers(project)

  const id = tickets.makeId()

  const inst = project.installer || {}
  const cli = project.client || {}

  return {
    title: null,
    assignee: null,
    description: null,
    id,
    project: project.id,
    inspectionDate: (project.inspection || {}).date,
    failure: {label: '[no label]', ...failure},
    client: {id: cli.id, name: cli.name || null, email: cli.email || null},
    installer: {id: inst.id, name: inst.name || null, email: inst.email || null},
    organization: auth.sunkaizenUser.organization,
    site: project.site,
    status: `Incomplete`,
    created: new Date(),
    creatorMap: {id: auth.sunkaizenUser.id, name: auth.sunkaizenUser.name, email: auth.sunkaizenUser.email},
    watchers: Object.values(watcherMap || {}).map((u) => u.id),
    watcherMap,
    intakeFailureId: failure.id,
    ...ticket,
  }
}

export function getWatchers(project) {
  const watchers = [project.installer, project.client, project.reviewer, project.inspection.inspector].filter((i) => i)
  const watcherMap = {}

  for (let watcher of watchers) {
    if (watcher.id) watcherMap[watcher.id] = watcher
  }
  return watcherMap
}
