import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Input} from 'antd'
import {auth} from './Auth'
import {history} from './history'
import SystemLogger from './SystemLogger'

const googleSignInBadge = './google.png'

const buttonClasses = 'flex-row color-white bold align-center justify-center padding-1 margin-0-5'
const overlayClasses = 'flex-row align-center justify-center'
const linkClasses = 'primary-color pointer'

const styles = {
  button: {
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: '125%',
    backgroundColor: 'cornflowerblue',
    border: 'none',
  },
  label: {fontSize: '125%', fontWeight: 'bold', paddingRight: '0.4em', flex: 1},
  overlay: {backgroundColor: 'rgba(0, 0, 0, 0.5)', position: 'absolute', width: '85%', height: '40%'},
  modal: {
    padding: '6rem 3rem 3rem 3rem',
    width: '50%',
    border: '0.1em solid darkgrey',
    boxShadow: '0.1em 0.1em 0.1em 0.1em darkgrey',
    borderRadius: '1rem',
  },
  link: {textDecoration: 'none'},
  display: (bool) => ({display: bool ? 'flex' : 'none'}),
  input: {flex: 1, margin: '1rem', padding: '0.5rem', borderRadius: '1rem'},
}

export default class Login extends React.Component {
  static propTypes = {history: PropTypes.object}

  constructor(props) {
    super(props)

    this.unPwSubmit = this.unPwSubmit.bind(this)
    this.googleLogin = this.googleLogin.bind(this)
    this.register = this.register.bind(this)
    this.submitRegister = this.submitRegister.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  state = {show: false, toggle: false, resetEmail: null}

  async unPwSubmit(e) {
    e.preventDefault()
    let {email, password} = e.target
    // console.log(username.value, password.value)
    try {
      await auth.usernamePasswordLogin(email.value, password.value)
      history.push('/')
    } catch (e) {
      const logger = new SystemLogger({})
      logger.error(e.message)
    }
  }

  register() {
    //   console.log("showing registration overlay")
    this.setState({show: true})
  }

  async submitRegister(e) {
    e.preventDefault()
    let {email, password} = e.target

    try {
      await auth.registerWithoutErrorCatching(email.value, password.value)
      this.setState({show: false})
      history.push('/register')
    } catch (e) {
      const logger = new SystemLogger({})

      if (e.code === 'auth/email-already-in-use') {
        logger.log(
          "This account already exists; try logging-in instead of registering. You can reset your password if you've forgotten it."
        )
      } else {
        logger.error(e.message)
      }
    }
  }

  async googleLogin() {
    try {
      await auth.googleLogin()
      history.push('/')
    } catch (e) {
      console.log('error signing in', e)
    }
  }

  async resetPassword() {
    try {
      await auth.sendPasswordResetEmailWithoutErrorCatching(this.state.resetEmail)
      this.toggle()
    } catch (e) {
      console.log(e)
      const logger = new SystemLogger({})
      logger.error(e.message)
    }
  }

  toggle() {
    this.setState({toggle: !this.state.toggle})
  }

  render() {
    return (
      <div className="flex-column justify-center align-center" style={{padding: '5rem'}}>
        <form
          id="login"
          data-testid="login-form"
          className="flex-column justify-center padding-0-5"
          style={{alignContent: 'center'}}
          onSubmit={this.unPwSubmit}
        >
          <div className="flex-row align-center justify-center">
            <div style={styles.label}>Email:</div>
            <input style={styles.input} name="email" type="text" data-cy="email" data-testid="login-email" />
          </div>
          <div className="flex-row justify-space-evenly align-center">
            <div style={styles.label}>Password:</div>
            <input
              style={styles.input}
              name="password"
              type="password"
              data-cy="password"
              data-testid="login-password"
            />
          </div>
          <div onClick={this.toggle} style={styles.link}>
            Forgot Password?
          </div>
          <button className={buttonClasses} style={{...styles.button, cursor: 'pointer'}}>
            Sign In
          </button>
        </form>
        <div style={{padding: '0.3em'}}>
          <span style={{paddingRight: '0.2em'}}>Don&apos;t have an account?</span>
          <span className={linkClasses} style={styles.link} onClick={this.register} data-testid="register">
            Register
          </span>
        </div>
        <div onClick={this.googleLogin}>
          <img alt="Google Login" src={googleSignInBadge} />
        </div>
        <div className={overlayClasses} style={{...styles.overlay, ...styles.display(this.state.show)}}>
          <form
            id="register"
            data-testid="register-form"
            onSubmit={this.submitRegister}
            style={styles.modal}
            className="margin-1 background-white flex-column aign-center justify-center"
          >
            <div className="flex-column">
              <div className="flex-row align-center">
                <div style={styles.label}>Email:</div>
                <Input
                  style={{...styles.input, backgroundColor: 'whitesmoke'}}
                  name="email"
                  type="text"
                  data-testid="register-email"
                />
              </div>
              <div className="flex-row align-center">
                <div style={styles.label}>Password:</div>
                <Input
                  style={{...styles.input, backgroundColor: 'whitesmoke'}}
                  name="password"
                  type="password"
                  data-testid="register-password"
                />
              </div>
              <div className="flex-row justify-space-evenly">
                <button className={buttonClasses} style={styles.button} type="submit" data-testid="register-submit">
                  Register
                </button>
                <button
                  className={buttonClasses}
                  style={styles.button}
                  type="button"
                  onClick={() => this.setState({show: false})}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
        <Modal
          title="Reset Password"
          visible={this.state.toggle}
          onCancel={this.toggle}
          okText="Send Password Reset Link"
          onOk={this.resetPassword}
        >
          <Input
            style={{marginBottom: '1rem'}}
            placeholder="Enter your email address"
            onChange={(e) => this.setState({resetEmail: e.target.value})}
          />
        </Modal>
      </div>
    )
  }
}
