import React from 'react';
import { Icon, List, Progress } from 'antd';
import { connect } from 'react-redux';
import { updateProgress } from '../action_creators';

const sToP = (state) => {
  //console.log('State Fields:', JSON.stringify(state.fields));
  return {
    active: state.active.section,
    project: state.project,
    sections: state.sections,
    fields: state.fields,
  };
};

const evaluateCondition = (condition, fields) => {
  const { selectedQuestion, selectedOption, selectedOperand } = condition;
  const field = fields[selectedQuestion];
  let fieldValue = field?.value;

  if (fieldValue === undefined) return false;

  // Extract the actual value for dropdown and radio button fields
  if (field.type === 'radio-button' || field.type === 'dropdown') {
    fieldValue = Object.keys(fieldValue.values)[0];
  }

  //console.log("selectedOperand :: " + selectedOperand + " selectedOption :: " + selectedOption + " fieldValue :: " + fieldValue);

  switch (selectedOperand) {
    case 'equal':
    case 'is': // Added case for 'is'
      return fieldValue == selectedOption;
    case 'not_equal':
      return fieldValue != selectedOption;
    case 'greater_than':
      return fieldValue > selectedOption;
    case 'less_than':
      return fieldValue < selectedOption;
    case 'contains':
      return Array.isArray(fieldValue) && fieldValue.includes(selectedOption);
    case 'not_contains':
      return Array.isArray(fieldValue) && !fieldValue.includes(selectedOption);
    default:
      return false;
  }
};

const isFieldVisible = (field, fields) => {
  if (!field || !field.conditionGroup || !field.conditionGroup.cgList || field.conditionGroup.cgList.length === 0) {
    return true;
  }

  const { operand, cgList } = field.conditionGroup;

  if (operand === 'all') {
    return cgList.every((group) => group.conditionList.every((condition) => evaluateCondition(condition, fields)));
  } else {
    return cgList.some((group) => group.conditionList.every((condition) => evaluateCondition(condition, fields)));
  }
};

export function ActiveSectionDisplay({ project = { intake: { order: [] } }, sections, active, fields }) {
  const orderedSections = project.intake.order.map((id) => sections[id]);

  // Calculate total questions and answered questions for each section
  const totalQuestions = orderedSections.reduce((total, section) => {
    return total + section.order.filter((fId) => fields[fId] && isFieldVisible(fields[fId], fields) && fields[fId].type !== 'info-box').length;
  }, 0);

  const answeredQuestions = orderedSections.reduce((total, section) => {
    return total + section.order.filter((fId) => fields[fId] && isFieldVisible(fields[fId], fields) && fields[fId]?.value && fields[fId].type !== 'info-box').length;
  }, 0);

  const percentComplete = totalQuestions ? Math.round((answeredQuestions / totalQuestions) * 100) : 0;

  // Log state and calculations
  //console.log('ActiveSectionDisplay - Total Questions:', totalQuestions);
  //console.log('ActiveSectionDisplay - Answered Questions:', answeredQuestions);
  //console.log('ActiveSectionDisplay - Percent Complete:', percentComplete);

  return (
    <div>
      <div style={{ padding: '1.5rem' }}>
        <Progress percent={percentComplete} strokeWidth={20} strokeColor='#234091' />
        <div className="progress-text" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          {answeredQuestions}/{totalQuestions} Answered
        </div>
      </div>
      <div style={{ borderBottom: '0.1rem solid #DFE3E8', padding: '1.5rem' }}>
        <List
          itemLayout="vertical"
          dataSource={orderedSections}
          renderItem={(item) => (
            <List.Item style={{ border: 'none', margin: 0, padding: 0 }}>
              <div className="full-width flex-row align-center">
                <div className="flex-row align-center justify-center" style={{ flex: 1 }}>
                  {item.order.filter((fId) => fields[fId] && isFieldVisible(fields[fId], fields) && fields[fId]?.value && fields[fId].type !== 'info-box').length === item.order.filter((fId) => fields[fId] && isFieldVisible(fields[fId], fields) && fields[fId].type !== 'info-box').length ? (
                    <Icon theme="filled" style={{ color:'green' }} type="check-circle" />
                  ) : (
                    <div
                      style={{
                        padding: '.75rem',
                        borderRadius: '1rem',
                        background: item.id === active ? '#234091' : '#C4CDD5',
                      }}
                    ></div>
                  )}
                </div>
                <div className="bold" style={{ flex: 5 }}>
                  {item ? item.title + ' (' + item.order.filter((fId) => fields[fId] && isFieldVisible(fields[fId], fields) && fields[fId]?.value && fields[fId].type !== 'info-box').length + '/' + item.order.filter((fId) => fields[fId] && isFieldVisible(fields[fId], fields) && fields[fId].type !== 'info-box').length + ')' : '[no section]'}
                </div>
                <div style={{ flex: 2 }}>{item ? item.status : 'Not Started'}</div>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}

const WrappedActiveSectionDisplay = connect(sToP, { updateProgress })(ActiveSectionDisplay);
export default WrappedActiveSectionDisplay;
