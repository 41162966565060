import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { SelectControlAction, InputCtrlAction, MultiInputControlAction } from './components/index';
import Ratings from '../DAL/Ratings'; // Importing Ratings for fetching ratings data
import Icons from '../DAL/Icons'; // Importing Icons for fetching icons data
import { actionData, dropdownDataFunc } from './actionsDataJSON'; // Importing action data and dropdown data functions
import ReactQuill from 'react-quill'; // Importing ReactQuill for rich text editor
import 'react-quill/dist/quill.snow.css'; // Importing ReactQuill CSS

// User can create actions. New Tab called Actions in form creation
const AddAction = ({
  action,
  handleDeleteActionCancel,
  handleDeleteActionConfirmation,
  handleDeleteAction,
  handleUpdateAction,
  currentIndex,
}) => {
  const [tags, setTags] = useState(action.tags);
  const [ratingsData, setRatingsData] = useState([]); // State for storing fetched ratings data
  const [iconsData, setIconsData] = useState([]); // State for storing fetched icons data

  const tagInfoObj = {
    id: '',
    text: '',
    isEdit: true,
    isDelete: false,
  };

  const ratings = new Ratings(); // Creating an instance of Ratings
  const icons = new Icons(); // Creating an instance of Icons

  useEffect(() => {
    const fetchData = async () => {
      const ratingsData = await ratings.getAllRatings(); // Fetching all ratings
      const iconsData = await icons.getAllIcons(); // Fetching all icons
      setRatingsData(ratingsData); // Setting fetched ratings data to state
      setIconsData(iconsData); // Setting fetched icons data to state
    };
    fetchData();
  }, []);

  const handleAddTags = () => {
    const tagsUpdated = [...tags];
    const tagInfoObjUpdated = { ...tagInfoObj };

    tagInfoObjUpdated.id = tagsUpdated.length + 1;
    tagInfoObjUpdated.text = '';
    tagInfoObjUpdated.isEdit = true;
    tagInfoObjUpdated.isDelete = false;

    tagsUpdated.push(tagInfoObjUpdated);
    setTags(tagsUpdated);

    const actionUpdated = { ...action };
    actionUpdated.tags = tagsUpdated;
    handleUpdateAction(actionUpdated);
  };

  const handleTagsUpdate = (e, obj) => {
    const editTags = [...tags];
    if (e.target.value) {
      const editedRec = editTags.find((x) => x.id === obj.id);
      editedRec.text = e.target.value;
      editedRec.isEdit = false;
      setTags(editTags);
    }

    const actionUpdated = { ...action };
    actionUpdated.tags = editTags;
    handleUpdateAction(actionUpdated);
  };

  const handleMaintainceUpdate = (e, obj) => {
    const editTags = [...tags];

    const editedRec = editTags.find((x) => x.id === obj.id);
    editedRec.maintaince = e.target.value;
    setTags(editTags);

    const actionUpdated = { ...action };
    actionUpdated.tags = editTags;
    handleUpdateAction(actionUpdated);
  };

  const handleEditTags = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.id === obj.id);
    editedRec.isEdit = true;
    setTags(editTags);

    const actionUpdated = { ...action };
    actionUpdated.tags = editTags;
    handleUpdateAction(actionUpdated);
  };

  const handleDeleteTags = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.id === obj.id);
    editedRec.isDelete = 'confirmation';
    setTags(editTags);

    const actionUpdated = { ...action };
    actionUpdated.tags = editTags;
    handleUpdateAction(actionUpdated);
  };

  const handleDeleteCancelTags = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.isDelete === 'confirmation');
    editedRec.isDelete = false;
    setTags(editTags);

    const actionUpdated = { ...action };
    actionUpdated.tags = editTags;
    handleUpdateAction(actionUpdated);
  };

  const handleDeleteConfirmation = (obj) => {
    const editTags = [...tags];
    const editedRec = editTags.find((x) => x.isDelete === 'confirmation');
    editedRec.isDelete = true;
    setTags(editTags);

    const actionUpdated = { ...action };
    actionUpdated.tags = editTags;
    handleUpdateAction(actionUpdated);
  };

  const handleStrategyUpdate = (e) => {
    const actionUpdated = { ...action };
    actionUpdated.strategy = e.target.value;
    handleUpdateAction(actionUpdated);
  };

  const handleActionDescriptionUpdate = (value) => {
    const actionUpdated = { ...action };
    actionUpdated.actionDescription = value;
    handleUpdateAction(actionUpdated);
  };

  const handleReferenceInformationUpdate = (e) => {
    const actionUpdated = { ...action };
    actionUpdated.referenceInformation = e.target.value;
    handleUpdateAction(actionUpdated);
  };

  const handleOnChangeRating = (value, opt) => {
    const property = opt.dbProp;
    const actionUpdated = { ...action };
    actionUpdated[property] = value;
    handleUpdateAction(actionUpdated);
  };

  const actionDataValues = actionData(ratingsData, iconsData); // Fetch action data with updated ratings and icons data
  const dropdownData = dropdownDataFunc(actionDataValues); // Generate dropdown data

  return (
    <React.Fragment>
      <div className="addAction">
        <h4>Action #{currentIndex}</h4>
        <div className="addActionBg">
          <div className="actionStrategy">
            <InputCtrlAction
              name={'Strategy'}
              uniqueValue={action.id}
              defaultValue={action.strategy}
              handleChange={(e) => handleStrategyUpdate(e, 'strategy')}
            />
          </div>
        </div>

        <div className="actionDescription" style={{ marginBottom: '8rem' }}>
          <label className="question-title">Action Description</label>
          <ReactQuill
            value={action.actionDescription || ''} onChange={handleActionDescriptionUpdate} style={{ height: '100px' }} />
        </div>

        <MultiInputControlAction
          name={'Reference Information'}
          uniqueValue={action.id}
          defaultValue={action.referenceInformation}
          shortNotes={'[Google](www.google.com) | [Yahoo](http://yahoo.com)'}
          handleChange={(e) => handleReferenceInformationUpdate(e)}
        />

        <div className="actionDropdown">
          <div className="actionDropdownDisplay">
            {dropdownData.map((ddD, index) => (
              <SelectControlAction
                key={`SelectAction-${ddD.name}-${index}-${ddD.unquieProp}`}
                name={ddD.name}
                options={ddD.options}
                uniqueValue={action[ddD.unquieProp]}
                defaultValue={action[ddD.dbProp]}
                handleChange={(e) => handleOnChangeRating(e, ddD)}
              />
            ))}
          </div>

          <div className="actionTags">
            <div>
              <label className="padding-right-30 actionTagsLabel" htmlFor="tags">
                Tags{' '}
              </label>
              <Button className="actionTagsButton" title="add-tag" icon="plus" onClick={() => handleAddTags()} />
            </div>

            {(tags || [])
              .filter((x) => x.isDelete !== true)
              .map((x) => (
                <div key={`tags-${x.id}`} className="actionTagsAdd">
                  <Button
                    className="actionTagEdit"
                    title="edit"
                    icon={'edit'}
                    onClick={() => handleEditTags(x)}
                    data-cy="toggleEditFormBtn"
                  />
                  {x.isEdit ? (
                    <input
                      className="ant-input ant-input-lg actionTagName"
                      key={`tags-${tags.length}-${x.id}-${x.text}`}
                      onBlur={(e) => handleTagsUpdate(e, x)}
                      placeholder={`please enter Tag name`}
                      type="text"
                      id="actionName"
                      name="actionName"
                      defaultValue={x.text}
                    />
                  ) : (
                    <label className="actionTagName">{x.text}</label>
                  )}

                  <Popconfirm
                    onCancel={handleDeleteCancelTags}
                    onConfirm={handleDeleteConfirmation}
                    title="Are you sure you want to delete tag?"
                  >
                    <Button
                      className="actionTagNameDelete"
                      title="delete"
                      icon={'delete'}
                      onClick={() => handleDeleteTags(x)}
                      data-cy="toggledeleteFormBtn"
                    />
                  </Popconfirm>
                  <div className="actionCommonClass">
                    {' '}
                    <textarea
                      cols="39"
                      key={`tags-${tags.length}-${x.id}-${x.maintaince}`}
                      className="actionTagDesc"
                      placeholder={`please enter Tag description`}
                      onBlur={(e) => handleMaintainceUpdate(e, x)}
                      type="text"
                      id="maintaince"
                      defaultValue={x.maintaince}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="actionDelete">
          <Popconfirm
            onCancel={() => handleDeleteActionCancel(action)}
            onConfirm={() => handleDeleteActionConfirmation(action)}
            title="Are you sure you want to delete Action?"
          >
            <Button
              className="actionDeleteConf"
              title="deleteAction"
              icon={'delete'}
              onClick={() => handleDeleteAction(action)}
              data-cy="toggledeleteActionFormBtn"
            />
          </Popconfirm>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddAction;
