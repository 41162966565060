import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import IntakeFailure from '../../IntakeFailure'
import {setTicketField, setCreateValid, setSelectedFailure} from '../../action_creators'
import {Title, Description, AssignedTo, Other, Urgency, Status, RejectionComment} from './Fields'
import { auth } from '../../../Auth'

function stop(state) {
  return {
    ticket: state.ticket,
    failures: Object.values(state.failures),
    selected: state.selectedFailure,
    project: state.project,
  }
}

const dtop = {setTicketField, setCreateValid, setSelectedFailure}

export function CreateTicket({ticket, failures, selected, setSelectedFailure, project, setTicketField}) {
  // const [selected, setSelected] = useState({})
  const [otherValid, setOtherValid] = useState(false)
  const [titleValid, setTitleValid] = useState(false)
  const [descriptionValid, setDescriptionValid] = useState(false)
  const [rejectionCommentValid, setRejectionCommentValid] = useState(false)

  useEffect(
    function () {
      setSelectedFailure(failures[0])
    },
    [failures.length]
  )
  useEffect(
    function () {
      if ([otherValid, titleValid, descriptionValid].every((i) => i)) setCreateValid(true)
      else setCreateValid(false)
    },
    [otherValid, titleValid, descriptionValid]
  )

  return (
    <div className="create-ticket">
      <div className="intake-failure-container">
        <IntakeFailure failure={selected} enhanced={true} />
      </div>
      <Title title={ticket.title} change={setTicketField} setValid={setTitleValid} />
      <Description description={ticket.description} change={setTicketField} setValid={setDescriptionValid} />
      <AssignedTo assignedTo={ticket.assignee} change={setTicketField} ticket={ticket} project={project} />
      {ticket.assignee === 'Other' && (
        <Other other={ticket.otherName} change={setTicketField} setValid={setOtherValid} />
      )}
      <Urgency change={(urgent) => setTicketField('priority', urgent ? 'Urgent' : 'Normal')} />
      {(ticket.status && auth.sunkaizenUser?.id === ticket?.creatorMap?.id) && <Status status={ticket.status} change={setTicketField} ticket={ticket} project={project}  />}
      
      {ticket?.status === 'Rejected' && <RejectionComment title={ticket?.rejectionComment || ''} change={setTicketField} setValid={setRejectionCommentValid} />}
          
    </div>
  )
}

const WrappedCreateTicket = connect(stop, dtop)(CreateTicket)
export default WrappedCreateTicket
