import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { Sticky } from 'react-sticky';
import { history } from '../history';
import { saveIntake } from './utilities';
import { connect } from 'react-redux';

export function TabBar({ project, highlightErrors, props, Default, saveAnswers }) {
  async function saver(route) {
    try {
      message.loading({ content: 'Saving data...', key: 'saving' });
      await saveIntake();
      message.success({ content: 'Data saved successfully!', key: 'saving', duration: 2 });
      if (route) history.push(route);
    } catch (e) {
      highlightErrors(e);
      message.error({ content: 'Error saving data.', key: 'saving', duration: 2 });
      console.log(`Some errors occurred that prevent submission`, e);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      saver();
    }, 90000); // Auto-save every 90 seconds

    return () => clearInterval(interval);
  }, []); // Only run on mount and unmount

  return (
    <Sticky>
      {({ style }) => (
        <div className="flex-row background-white justify-space-between" style={{ ...style, zIndex: 10 }}>
          <Default {...props} style={{ width: '100%' }} />
          <div className="flex-row" style={{ display: 'none' }}>
            <Button style={{ margin: `1rem 1rem 1rem 0` }} type="default" onClick={() => saver()}>
              Save
            </Button>
            <Button
              style={{ margin: `1rem 0 1rem 0` }}
              type="primary"
              onClick={() => saver(`/projects/${project.id}/manage`)}
            >
              Save and Exit
            </Button>
          </div>
        </div>
      )}
    </Sticky>
  );
}

export default connect()(TabBar);
