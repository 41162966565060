import BaseModel from './BaseModel';
import { db, firebase } from '../utilities';

class Icons extends BaseModel {
  constructor() {
    super(db.collection('icons'));
  }

  async getAllIcons() {
    try {
      const snapshot = await this.baseQuery.get();
      const icons = snapshot.docs.map((doc: { id: any; data: () => any; }) => ({ id: doc.id, ...doc.data() }));
      return icons;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createIcon(icon: { name: string; description?: string; image?: string; }) {
    try {
      const snapshot = await this.baseQuery.where('name', '==', icon.name).get();
      if (!snapshot.empty) {
        throw new Error('Icon name must be unique');
      }
      await this.baseQuery.add(icon);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateIcon(id: string, icon: { name: string; description?: string; image?: string; }) {
    try {
      const snapshot = await this.baseQuery
        .where('name', '==', icon.name)
        .where(firebase.firestore.FieldPath.documentId(), '!=', id)
        .get();
      if (!snapshot.empty) {
        throw new Error('Icon name must be unique');
      }
      await this.baseQuery.doc(id).update(icon);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteIcon(id: string) {
    try {
      await this.baseQuery.doc(id).delete();
    } catch (error) {
      console.error( error);
      throw error;
    }
  }
}

export default Icons;
