import {db} from '../../utilities'
import DB from '../../DAL/DB'

const users = new DB.Users()

export default async function setInspector(project, inspector, returns) {
  if (!inspector || !inspector.id) {
    project.inspection = null
    return
  }

  const calendar = new DB.UserCalendars(inspector.id)

  // check whether the user has changed the inspector; if they have, replace the calendar entry
  const {inspection = {inspector: {}}} = project

  // if the provided inspector is different from the one on the project, then replace the calendar entry with the new user
  if (inspection && inspection.inspector && inspection.inspector.id !== inspector.id) {
    // delete any old calendared inspections for the project
    if (inspection.inspector.id) {
      const {data: appointments, ok} = await calendar.get(null, {filters: [['id', '==', project.id]]})

      console.log(0)
      if (ok && appointments.length > 0) {
        for (let appointment of appointments) {
          await calendar.delete(appointment.id)
        }
      }
    }
  }

  const {data, ok} = await users.get(inspector.id)

  if (!ok) throw new Error('the new inspector for the project is not in the database; this should be impossible')

  const [{name, id, email, phone = null, organization}] = data
  project.inspection = {inspector: {name, id, email, phone, organization}}

  returns.push([calendar.update(project.id, null, project)])
}
