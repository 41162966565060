import React, { useState, useEffect } from 'react';
import { Input, Button, Checkbox } from 'antd'; // Import Checkbox from antd
import { connect } from 'react-redux';
import { modify } from './action_creators';

const stop = (state) => {
  const { title, created, updated, skipReview } = state.form; // Include skipReview state
  return { title, created, updated, skipReview };
};

const formModify = modify('form_field');
const dtop = { formModify };

export function FormSettings({ title, created, updated, skipReview, formModify, handleTitleUpdate }) {
  const [editing, setEditing] = useState(false);
  const [eTitle, setETitle] = useState(title);
  const [skipReviewState, setSkipReviewState] = useState(skipReview || false);

  useEffect(() => {
    if (eTitle !== title) formModify('title', eTitle);
  }, [editing]);

  useEffect(() => {
    setETitle(title);
    handleTitleUpdate(title);
  }, [title]);

  const handleSkipReviewChange = (e) => {
    const checked = e.target.checked;
    setSkipReviewState(checked);
    formModify('skipReview', checked); // Update form state with skipReview
  };

  return (
    <div>
    <div className="form-settings-container padding-1">
      <div className="flex-row align-center">
        <div className="margin-right-2 flex-row">
          {editing ? (
            <Input
              className="margin-bottom-1"
              size="large"
              value={eTitle}
              style={{ marginRight: '1rem' }}
              onChange={(e) => setETitle(e.target.value)}
              data-cy="formInput"
            />
          ) : (
            <h2>{title}</h2>
          )}
          <Button
            style={{ marginLeft: '1rem' }}
            title="edit"
            icon={editing ? 'check' : 'edit'}
            onClick={() => setEditing(!editing)}
            data-cy="toggleEditFormBtn"
          />
        </div>
      </div>
      <div className="flex-row align-center">
        <div className="margin-left-2">
          {created && (
            <div className="flex-row">
              <div className="bold padding-right-1">Created:</div>
              <div>{created.toDate().toLocaleString()}</div>
            </div>
          )}
          {updated && (
            <div className="flex-row">
              <div className="bold padding-right-1">Updated:</div>
              <div>{updated.toDate().toLocaleString()}</div>
            </div>
          )}
        </div>
      </div>
    </div>
    
      <Checkbox
          checked={skipReviewState}
          onChange={handleSkipReviewChange}
          data-cy="skipReviewCheckbox"
        >
          Skip Review Process
        </Checkbox>
    </div>
  );
}

const WrappedFormSettings = connect(stop, dtop)(FormSettings);
export default WrappedFormSettings;
