import DB from '../../DAL/DB'
import {auth} from '../../Auth'
import CalendarEntry from '../CalendarEntry'
import moment from 'moment'

export const prefixShorts = {
  'Cancel Requested': 'CR',
  'Canceled': 'C',
  'Rescheduled': 'R',
  'Scheduled': 'S',
  'Completed': 'CO',
  'Intake': 'I',
}

const calendars = new DB.Calendars()

function _makeDataMap(dataList) {
  const dataMap = {}
  for (let d of dataList) {
    dataMap[d.id] = new CalendarEntry({data: d})
  }
  return dataMap
}

const redColor = `#cc0000`
const lightRedColor = `#f39595`

const formatFirestoreTimestamp = (timestamp) => {
  // Convert Firestore timestamp to a JavaScript Date object
  const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  // Format the date using Moment.js
  return moment(date).format('hh:mm A');
};


const replaceIntake = (str) => {
  return str.replace(/^Intake\s/, 'I ');
}

export function subscribeAvailability(dispatch, { start, end }) {
  return function () {
    const options = {filters: []}

    options.sort = 'date'
    options.direction = 'desc'

    options.filters.push(['date', '>=', start])
    options.filters.push(['date', '<', end])

    options.filters.push(['data.organization.id', '==', auth.sunkaizenUser.organization.id])

    if (!['client', 'admin', 'super-admin', 'scheduler'].includes(auth.sunkaizenUser.type)) {
      options.filters.push(['type', '==', 'Availability'])
    }

    if (auth.sunkaizenUser.type === 'field-user') {
      options.filters.push(['data.id', '==', auth.sunkaizenUser.id])
    }

    return calendars.subscribe(null, options, ({ok, data}) => {
      //console.info('availability fetched:', data)

      for (let a of data) {
        a.color = 'blue'
      }

      if (ok) dispatch({type: 'availability', value: _makeDataMap(data)})
    })
  }
}

export function subscribeEvents(dispatch, { start, end}) {
  return function () {
    const options = {filters: []}
    
    options.sort = 'date'
    options.direction = 'desc'

    options.filters.push(['date', '>=', start])
    options.filters.push(['date', '<', end])

    if (['client', 'admin', 'super-admin', 'scheduler'].includes(auth.sunkaizenUser.type)) {
      // options.filters.push(['data.organization.id', '==', auth.sunkaizenUser.organization.id])
      options.filters.push(['type', '==', 'Intake'])
    } else if (auth.sunkaizenUser.type === 'installer') {
      options.filters.push(['data.installer.id', '==', auth.sunkaizenUser.id])
    } else {
      options.filters.push(['data.inspection.inspector.id', '==', auth.sunkaizenUser.id])
    }

    options.filters.push()

    return calendars.subscribe(null, options, ({ok, data}) => {
      //console.info('events fetched:', data)

      const newEvents = []
      data.forEach(each => {
        if (each.data?.intakeCancellations) {
          each.data.intakeCancellations.forEach(cancellation => {
            newEvents.push({ 
              data: each.data, //parent's 
              date: cancellation.canceledDate,
              duration: cancellation.canceledDate, // frankly duration does not make a difference , I have seen even older durations 
              end: cancellation.canceledDate,
              endDate: cancellation.canceledDate,
              id: `${each.id}_${cancellation.canceledDate.seconds}`, 
              instakeCancellation: [],
              start: cancellation.canceledDate,
              title: replaceIntake(each.title), 
              type: each.type, 

              color: redColor,
              // status: cancellation?.rescheduled ? 'Rescheduled' : 'Canceled', 
              parent: each.id, 
              customPrefix: cancellation?.rescheduled ? prefixShorts['Rescheduled']: prefixShorts['Canceled'],
              customCancelDate: formatFirestoreTimestamp(cancellation.canceledDate) 
            })
          })
        }
      })  
      const _data = _makeDataMap([...data, ...newEvents])
      //console.log('events fetched _data',_data)


      if (ok) dispatch({type: 'events', value: _data  })
    })
  }
}

const getDatesBetween = (startDate, endDate) => {
  const dates = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

const getMonthsBetween = (startDate, endDate) => {
  const months = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      if (!months.includes(month)) {
          months.push(month);
      }
      currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
}


const isDateInRange = (cancelledDate, startDate, endDate) => {
  // Convert Firestore Timestamp to JavaScript Date
  const cancelledDateObj = cancelledDate.toDate();
  
  // Set time of startDate and endDate to the start of the day
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  
  // Set time of cancelledDate to the start of the day
  cancelledDateObj.setHours(0, 0, 0, 0);

  // Check if cancelledDate is within the range
  return cancelledDateObj >= startDate && cancelledDateObj <= endDate;
}


export function subscribeCancelledIntake(dispatch, { start, end }) {

  //console.log('subscribeCancelledIntake',start, 'end', end)

  return function () {
    const options = {filters: []}

    //console.log('date range',getMonthsBetween(start, end))
    
    options.sort = 'date'
    options.direction = 'desc'

    options.filters.push(['intakeCancellations', 'array-contains-any', [...getMonthsBetween(start, end)]])
    //options.filters.push(['date', '<', start]); // Dates less than start
    //options.filters.push(['date', '>=', end]);  // Dates greater than or equal to end
    

    // if (['client', 'admin', 'super-admin', 'scheduler'].includes(auth.sunkaizenUser.type)) {
    //   // options.filters.push(['data.organization.id', '==', auth.sunkaizenUser.organization.id])
    //   options.filters.push(['type', '==', 'Intake'])
    // } else if (auth.sunkaizenUser.type === 'installer') {
    //   options.filters.push(['data.installer.id', '==', auth.sunkaizenUser.id])
    // } else {
    //   options.filters.push(['data.inspection.inspector.id', '==', auth.sunkaizenUser.id])
    // }

    //options.filters.push()

    //console.log('options',options)

    return calendars.subscribe(null, options, ({ok, data}) => {
      //console.info('subscribeCancelledIntake fetched:', data)

      const newEvents = []
      data.forEach(each => {
        if (each.data?.intakeCancellations) {
          each.data.intakeCancellations.forEach(cancellation => {
            newEvents.push({ 
              data: each.data, //parent's 
              date: cancellation.canceledDate,
              duration: cancellation.canceledDate, // frankly duration does not make a difference , I have seen even older durations 
              end: cancellation.canceledDate,
              endDate: cancellation.canceledDate,
              id: `${each.id}_${cancellation.canceledDate.seconds}`, 
              instakeCancellation: [],
              start: cancellation.canceledDate,
              title: replaceIntake(each.title), 
              type: each.type, 

              color: redColor,
              // status: cancellation?.rescheduled ? 'Rescheduled' : 'Canceled', 
              parent: each.id, 
              //customPrefix: cancellation?.rescheduled ? `Rescheduled`: `Canceled`,
              customPrefix: cancellation?.rescheduled ? prefixShorts['Rescheduled']: prefixShorts['Canceled'],
              customCancelDate: formatFirestoreTimestamp(cancellation.canceledDate) 
            })
          })
        }
      })  
      const _data = _makeDataMap([...newEvents])
      //console.log('cancelledIntakes _data',_data)


      // now I need to compare if data.date 
        //const filteredDate = data.filter(each => !isDateInRange(each.date, start, end))
        // data.forEach(each => {
        //   console.log(` each.date: ${each.id} ${each.date} :  `,isDateInRange(each.date, start, end))
        // })
        //console.log('filteredDate',filteredDate)

      if (ok) dispatch({type: 'cancelledIntakes', value: _data })
    })
  }
}
