import {useEffect, useContext} from 'react'
import DB from '../../DAL/DB'
import {ReducerContext} from '../reducer'
import {pushData} from '../utilities'
import loadProjectSchedule from './loadProjectSchedule'

const projects = new DB.Projects()

export default function useLoadSchedule(id) {
  const {state, dispatch} = useContext(ReducerContext)
  const push = pushData({state, dispatch})

  // if the user is accessing the scheduler from projectdetail, load the selected project
  useEffect(function () {
    (async function () {
      if (id) {
        const {
          ok,
          data: [project],
        } = await projects.get(id)
        if (ok) {
          const schedule = loadProjectSchedule(project)
          push(schedule)
          dispatch({type: 'projects', value: {[project.id]: project}})
        }
      }
    })()
  }, [])

  // whenever a new project is selected, load it into state so the component can display its assignment traits
  useEffect(
    function () {
      if (!state.project.id) return
      const schedule = loadProjectSchedule(state.project)

      for (let k in schedule) {
        if (!schedule[k]) delete schedule[k]
      }

      push({date: state.date, time: state.time, ...schedule})
    },
    [state.project.id]
  )
}
