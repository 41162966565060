import {useEffect, useState} from 'react'
import {store} from '../utilities'
import {blankFields} from '../action_creators'
import {finalizeSingleProject} from '../utilities'
import {useLoggerEffect} from '../../effects'

export default function useFinalize(id, done, setDone, success) {
  const [loading, setLoading] = useState(false)
  const logger = useLoggerEffect(`/projects/${id}`)

  async function finalize() {
    if (loading) return

    setLoading(true)

    try {
      logger.categoryId = id || null
      const state = store.getState()

      const s = await finalizeSingleProject(id, state.projectBase, state.fields, logger)

      console.log('finalize after finalizeSingleProject', s)

      if (s === 'success') {
        success.map((f) => f())
        store.dispatch(blankFields())
        logger.log('Project successfully created')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      setDone(null)
    }
  }

  useEffect(
    function () {
      switch (done) {
        case 'done':
          (async function () {
            await finalize()
          })()
          return
        case 'clear':
          store.dispatch(blankFields())
          return
        default:
          return
      }
    },
    [done]
  )
}
