import React from 'react'
import {List} from 'antd'

const transformMap = {
  Created: (timestamp) => timestamp.toDate().toDateString(),
  'Last Modified': (timestamp) => timestamp.toDate().toDateString(),
  Client: (client) => client.name,
}

export default function MiscInfo({client, created, updated}) {
  const projectData = [
    {content: client, title: `Client`},
    {content: created, title: `Created`},
    {content: updated, title: `Last Modified`},
  ]

  function transform(item) {
    const f = transformMap[item.title]
    if (!item.content) return item.content
    return f ? f(item.content) : item.content
  }

  return (
    <div className="misc-info">
      <List
        itemLayout="horizontal"
        dataSource={projectData}
        renderItem={(item) => (
          <List.Item className="misc-info-item">
            <div className="flex-row full-width justify-space-between">
              <div className="bold">{item.title}</div>
              <div>{transform(item)}</div>
            </div>
          </List.Item>
        )}
      />
    </div>
  )
}
