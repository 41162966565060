import React, {useEffect, useState} from 'react'
import {Input, Button} from 'antd'
import {subscribe} from '../HOC/Firebase/SubscribeHOC'
import {saveComment} from './utilities'

export function TicketComments({ticket, makeSubscription, subscribeResources}) {
  const [edit, setEdit] = useState(false)
  const [comment, setComment] = useState(null)

  useEffect(
    function () {
      if (!ticket.id) return

      makeSubscription({paths: [{collection: 'tickets', doc: ticket.id}, {collection: 'comments'}]}, 'comments')
    },
    [ticket]
  )

  const {comments = []} = subscribeResources

  return (
    <div className="comments-section">
      <h2 className="section-heading">Comments</h2>
      <div className="comments">
        {comments
          .sort((a, b) => (a.created.seconds > b.created.seconds ? 1 : -1))
          .map((comment) => (
            <div key={comment.id} className="comment">
              <strong>{comment.created.toDate().toDateString()}</strong>
              <em>{(comment.authorMap || {}).name}:</em>
              {comment.text}
            </div>
          ))}
        {edit && (
          <Input.TextArea
            className="comment-box"
            placeholder="Add your comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        )}
        <Button className="comment-button" onClick={() => setEdit(!edit)} data-cy="ticketCommentBtn">
          {edit ? `Cancel Comment` : `Add Comment`}
        </Button>
        {edit && (
          <Button
            type="primary"
            onClick={async () => {
              await saveComment(ticket.id, comment)
              setComment(null)
            }}
          >
            Save Comment
          </Button>
        )}
      </div>
    </div>
  )
}

const WrappedTicketComments = subscribe()(TicketComments)
export default WrappedTicketComments
