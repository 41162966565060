import React from 'react'
import {auth} from '../../../Auth'
import {connect} from 'react-redux'
import {Button} from 'antd'

function stop(state) {
  return {ticket: state.ticket}
}

export function ModalOpenButton({setShow, ticket}) {
  const {type, id} = auth.sunkaizenUser
  const isAdmin = [`admin`, `super-admin`].includes(type)
  const isCreator = id === ticket.creator && ![`Completed`, `Submitted`].includes(ticket.status)

  return (
    <div>
      {isAdmin || isCreator ? (
        <Button
          size="large"
          type="primary"
          className="edit-button"
          style={{padding: `0 8rem`}}
          onClick={() => setShow(true)}
        >
          Edit
        </Button>
      ) : null}
    </div>
  )
}

const WrappedModalOpenButton = connect(stop)(ModalOpenButton)
export default WrappedModalOpenButton
