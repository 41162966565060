import {db} from '../../utilities'
import {makeSiteAddress, assembleProject} from '.'

export default async function projectModify(project, fields) {
  const projRef = db.collection(`projects`).doc(project.id)
  const siteRef = db.collection(`sites`).doc(project.site.id)

  try {
    const {latitude, longitude} = fields
    const {siteAddress = ``, dummyAddress = ``, sameAddress = false} = fields
    const {siteOwnerName = ``, sameRep = false, siteRepName = ``, siteRepEmail = ``, siteRepPhone = ``} = fields
    const {client = {id: ``, name: ``, qualifications: {}}, inspector, installer, formRubrics} = fields
    const {projectType, rubric, organization} = fields
    const {restrict_schedule} = fields

    let site = {
      siteOwnerName,
      sameRep,
      siteRepName,
      siteRepEmail,
      siteRepPhone,
      id: siteRef.id,
      siteAddress,
      sameAddress,
    }

    site = await makeSiteAddress({project, site, dummyAddress, siteAddress, latitude, longitude})
    await siteRef.set(site)

    await assembleProject({
      client,
      installer,
      inspector,
      rubric,
      organization,
      site,
      project,
      projRef,
      projectType,
      restrict_schedule,
      formRubrics
    })
  } catch (e) {
    console.log(`an error occurred while updating a project: `, e)
    throw e
  }
}
