import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import {Form, Select} from 'antd'
import {fieldNameConverter} from '../../validators'
import {setField} from '../action_creators'
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  fields: state.fields,
  installers: (Object.values(state.dropdowns.users || {}) || []).filter((user) => user.type === 'installer' && !user.disabled),
  organizations: (Object.values(state.dropdowns.organizations || {}) || []).filter((o) => {
    if (!o || !Array.isArray(o.roles)) return false
    return (o.roles || []).includes('Installer')
  }),
})
const mapDispatchToProps = (dispatch) => ({setField: (field) => (value) => dispatch(setField(field, value))})

function InstallerInformation({fields, setField, installers, organizations}) {
  if (!fields) fields = {}
  const [installerMap, setInstallerMap] = useState({})

  useEffect(
    function () {
      const im = {}

      for (let installer of installers) {
        im[installer.id] = installer
      }
      for (let o of organizations) {
        im[o.id] = o
      }
      setInstallerMap(im)
    },
    [installers]
  )

  return (
    <div className="flex-column align-start padding-2 margin-bottom-1" style={{background: `#F4F6F8`}}>
      <h3>Installer Information</h3>
      <Form.Item className="full-width margin-bottom-1 padding-right-1">
        <Select
          initialValue={fields.installer}
          value={fields.installer ? fields.installer.name : undefined}
          onChange={(e) => {
            setField(`installer`)(installerMap[e] || null)
          }}
          placeholder={fieldNameConverter[`installer`]}
          size="large"
          className="full-width"
          data-cy="installer"
        >
          {Object.values(Object.values(installerMap))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((installer) => (
              <Select.Option key={installer.id} value={installer.id}>
                {installer.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      {fields[`installer`] && (
        <div className="full-width">
          <h4>Select NABCEP IDs</h4>
          <Select
            mode="multiple"
            className="full-width"
            onChange={(e) => setField(`nabcep_ids`)(e)}
            value={fields.nabcep_ids}
            data-cy="nabcepIds"
          >
            {((fields.installer || {}).nabcep_ids || ``).split(`,`).map((id, i) => {
              const trimmed = id.trim()
              if (!_.isEmpty(trimmed)) {
                return (
                  <Select.Option key={i} value={trimmed} label={trimmed}>
                    {trimmed}
                  </Select.Option>
                )
              }
            })}
          </Select>
        </div>
      )}
    </div>
  )
}

const WrappedInstallerInformation = connect(mapStateToProps, mapDispatchToProps)(InstallerInformation)
export default WrappedInstallerInformation
