import { store } from './utilities';
import DB from '../DAL/DB';

export const setProject = (project) => ({ type: 'SET_PROJECT', project });
export const setField = (id, value) => ({ type: 'SET_FIELD', id, value });
export const setSection = (section) => ({ type: 'SET_SECTION', section });
export const setCorrection = (id, correction = undefined) => ({ type: 'SET_CORRECTION', id, correction });
export const setActive = ({ name, value }) => ({ type: 'SET_ACTIVE', name, value });
export const setStatus = (status) => ({ type: 'SET_STATUS', status });
export const setLoading = (loading) => ({ type: 'SET_LOADING', loading });
export const photoViewer = (value) => ({ type: 'PHOTO_VIEWER', value });
export const reviewMode = (value) => ({ type: 'REVIEW_MODE', value });
export const resetState = () => ({ type: 'RESET' });
export const highlightErrors = (errors) => ({ type: 'HIGHLIGHT_ERRORS', errors });
export const drag = (dragging) => ({ type: 'DRAGGING', dragging });
export const onDragStart = (dragging) => ({ type: 'DRAGGING', dragging });
export const updateFieldsState = (value) => ({ type: 'UPDATE_FIELDS_VALUES_AUTORUBRIC', value });

export const deletePhoto = (name, id) => {
  const state = store.getState();
  const field = { ...state.fields[id] };
  delete field.images[name];
  return setField(id, field);
};

export const setFollowup = ({ id, value, type, followup }) => {
  const state = store.getState();

  const field = state.fields[id];
  if (!field.value.followup) field.value.followup = { [value]: { [type]: followup } };
  else {
    if (field.value.followup[value]) field.value.followup[value] = { ...field.value.followup[value], [type]: followup };
    else field.value.followup[value] = { [type]: followup };
  }

  return setField(id, field);
};

export const setFields = (fields) => {
  const fieldsWithVisibility = addVisibilityToFields(fields);
  return { type: 'SET_FIELDS', fields: fieldsWithVisibility };
};

const addVisibilityToFields = (fields) => {
  return Object.keys(fields).reduce((acc, key) => {
    acc[key] = {
      ...fields[key],
      visible: fields[key].visible !== false,  // Default visibility to true if not explicitly false
    };
    return acc;
  }, {});
};

const evaluateCondition = (condition, fields) => {
  const { selectedQuestion, selectedOption, selectedOperand } = condition;
  const field = fields[selectedQuestion];
  let fieldValue = field?.value;

  if (fieldValue === undefined) return false;

  // Extract the actual value for dropdown and radio button fields
  if (field.type === 'radio-button' || field.type === 'dropdown') {
    fieldValue = Object.keys(fieldValue.values)[0];
  }

  //console.log("selectedOperand :: " + selectedOperand + " selectedOption :: " + selectedOption + " fieldValue :: " + fieldValue);

  switch (selectedOperand) {
    case 'equal':
    case 'is': // Added case for 'is'
      return fieldValue == selectedOption;
    case 'not_equal':
      return fieldValue != selectedOption;
    case 'greater_than':
      return fieldValue > selectedOption;
    case 'less_than':
      return fieldValue < selectedOption;
    case 'contains':
      return Array.isArray(fieldValue) && fieldValue.includes(selectedOption);
    case 'not_contains':
      return Array.isArray(fieldValue) && !fieldValue.includes(selectedOption);
    default:
      return false;
  }
};

const isFieldVisible = (field, fields) => {
  if (!field || !field.conditionGroup || !field.conditionGroup.cgList || field.conditionGroup.cgList.length === 0) {
    return true;
  }

  const { operand, cgList } = field.conditionGroup;

  if (operand === 'all') {
    return cgList.every((group) => group.conditionList.every((condition) => evaluateCondition(condition, fields)));
  } else {
    return cgList.some((group) => group.conditionList.every((condition) => evaluateCondition(condition, fields)));
  }
};

const isFieldAnswered = (field) => {
  if (!field) return false;

  const fieldValue = field.value;

  if (field.type === 'radio-button' || field.type === 'dropdown') {
    return fieldValue && Object.keys(fieldValue.values).length > 0;
  }

  return !!fieldValue;
};

export const updateProgress = () => (dispatch, getState) => {
  const state = getState();
  const fields = state.fields;

  const visibleFields = Object.values(fields).filter(field => isFieldVisible(field, fields) && field.type !== 'info-box');
  const totalQuestions = visibleFields.length;
  const answeredQuestions = visibleFields.filter(isFieldAnswered).length;
  const percentComplete = totalQuestions ? Math.round((answeredQuestions / totalQuestions) * 100) : 0;

  //console.log('Total Questions:', totalQuestions);
  //console.log('Answered Questions:', answeredQuestions);
  //console.log('Percent Complete:', percentComplete);

  dispatch({ type: 'UPDATE_PROGRESS', progress: { totalQuestions, answeredQuestions, percentComplete } });
};

export const fetchFormDetails = (formId) => async (dispatch, getState) => {
  try {
    const state = getState();
    if (state.project.formFetched) {
      return;
    }
    const response = await DB.Forms.get(formId);
    const formDetails = response.data;
    dispatch({ type: 'form', value: formDetails });
    if (formDetails.logo) {
      dispatch(setFormLogo(formDetails.logo.url));
    }
    dispatch({ type: 'SET_FORM_FETCHED' });
  } catch (error) {
    console.error("Error fetching form details:", error);
  }
};

export const setFormLogo = (url) => ({ type: 'SET_FORM_LOGO', payload: url });
