import { combineReducers } from 'redux';

function form(state = {}, action) {
  switch (action.type) {
    case 'form_field':
      return { ...state, [action.key]: action.value };
    case 'form':
      return action.value;
    case 'clear':
      return {};
    case 'saveEnterprisePDFOption':
      return { ...state, saveEnterprisePDFOptionChecked: action.value };
    case 'SET_COVER_PAGE_TEXT':
      return { ...state, coverPageText: action.payload };
    case 'SET_KEYS_PAGE_TEXT':
      return { ...state, keysPageText: action.payload };
    case 'SET_CATEGORY_DESCRIPTIONS_TEXT':
      return { ...state, categoryDescriptions: action.payload };
    case 'SET_SUMMARY_OF_ACTION_ITEMS_TEXT':
      return { ...state, summaryOfActionItems: action.payload };
    case 'SET_ACCESS_SETTINGS':
      return { ...state, accessSettings: action.payload };
    case 'SET_SKIP_REVIEW':
      return { ...state, skipReview: action.payload }; // Handle skipReview
    default:
      return state;
  }
}

function sections(state = {}, action) {
  switch (action.type) {
    case 'section':
      return { ...state, [action.key]: action.value };
    case 'sections':
      return action.value;
    case 'deletesection':
      const updatedSections = { ...state };
      delete updatedSections[action.key];
      return updatedSections;
    case 'clear':
      return {};
    default:
      return state;
  }
}

function fields(state = {}, action) {
  switch (action.type) {
    case 'field':
      return { ...state, [action.key]: action.value };
    case 'fieldDependency':
      return { ...state, state: action.value };
    case 'fields':
      return { ...state, ...action.value };
    case 'deletefield':
      const updatedFields = { ...state };
      delete updatedFields[action.key];
      return updatedFields;
    case 'clear':
      return {};
    default:
      return state;
  }
}

function options(state = {}, action) {
  switch (action.type) {
    case 'option':
      return { ...state, [action.key]: action.value };
    case 'options':
      return action.value;
    case 'deleteoption':
      const updatedOptions = { ...state };
      delete updatedOptions[action.key];
      return updatedOptions;
    case 'clear':
      return {};
    default:
      return state;
  }
}

function active(state = { section: null, field: null, option: null }, action) {
  switch (action.type) {
    case 'active':
      return { ...state, [action.key]: action.value };
    case 'clear':
      return { section: null, field: null, option: null };
    default:
      return state;
  }
}

function editing(state = null, action) {
  if (action.type === 'edit') {
    return action.value;
  } else return state;
}

function modified(state = false, action) {
  if (action.type === 'modified') return action.value;
  else return state;
}

function loaded(state = { sections: [] }, action) {
  if (action.type === 'loadsection') return { ...state, sections: [...state.sections, action.value] };
  else return state;
}

const actionList = [];
function actionsState(state = { actionList: [] }, action) {
  switch (action.type) {
    case 'saveAction':
      return { ...state, actionList: action.value };
    default:
      return state;
  }
}

function rubrics(state = { rubrics: [] }, action) {
  switch (action.type) {
    case 'rubrics':
      return { ...state, rubrics: action.payload };
    default:
      return state;
  }
}

function clients(state = { clients: [] }, action) {
  switch (action.type) {
    case 'clients':
      return { ...state, clients: action.payload };
    default:
      return state;
  }
}

// Reducer for managing special access settings
function specialAccess(state = { data: [], status: null, error: null }, action) {
  switch (action.type) {
    case 'SAVE_SPECIAL_ACCESS_SUCCESS':
      return { ...state, status: 'success', data: [...state.data, action.payload], error: null };
    case 'SAVE_SPECIAL_ACCESS_FAILURE':
      return { ...state, status: 'failure', error: action.payload };
    case 'FETCH_SPECIAL_ACCESS_SUCCESS':
      return { ...state, status: 'fetched', data: action.payload, error: null }; // Handle fetch success
    case 'DISABLE_SPECIAL_ACCESS_SUCCESS':
      return {
        ...state,
        data: state.data.map((record) =>
          record.id === action.payload ? { ...record, status: 'disabled' } : record
        ),
      };
    default:
      return state;
  }
}


const rootReducer = combineReducers({
  active,
  form,
  sections,
  fields,
  options,
  editing,
  modified,
  loaded,
  actionsState,
  rubrics,
  clients,
  specialAccess, // Add the specialAccess reducer
});

// Add action for skipReview
export const setSkipReview = (value) => ({
  type: 'SET_SKIP_REVIEW',
  payload: value,
});

export default rootReducer;
