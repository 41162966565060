import React, { useEffect } from 'react'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
// import {history} from "./history"

import {auth} from './Auth'
//import {db} from './utilities'

import './styles.css'

import GarbageProjectExporter from './GarbageProjectExporter'

// import NotFound from './NotFound'
import CheckRegistration from './CheckRegistration'
// import Header from "./Sidebar/Header"
import Sidebar from './Sidebar'
import Login from './Login'
import Register from './Register'
import ScheduleInspections from './ScheduleInspections/TopLevelState'
import SystemSettings from './SystemSettings'
import ProjectList from './ProjectList/TopLevelState'
import ProjectInspectionsList from './ProjectList/ProjectsInspections'
import ProjectRequestsList from './ProjectList/ProjectsRequests'
import UserList from './UserList/TopLevelState'
import Rubrics from './Rubrics'
import FormsList from './FormList/FormsList'
import FormEdit from './FormDetail/Provider'
import {DetailedProjectView} from './DetailedProjectView' // ProjectDetail page
import {DetailedUserView} from './DetailedUserView'
import Dashboard from './Dashboards/DashboardLanding'
import ProjectNotifications from './ProjectNotifications'
import ScoringSystemsPage from './ScoringSystems/ScoringSystemsPage'
import PdfTest from './PdfTest'
// import Testing from "./Testing"
import AutocompleteField from './AutocompleteField'
import EmailLogin from './EmailLogin'
import DoIntake from './DoIntake'
import SystemNotifier from './Logging/SystemNotifier'
import Calendar from './Calendar/Calendar'
// import Reports from "./Reports/TopLevelState"
import Reports from './Reports'
import FieldUserDirectory from './FieldUserDirectory'
import InstallerDirectory from './InstallerDirectory'
import Analytics from './Analytics/TopLevelState'
import Testbed from './Testbed'

import TicketModal from './TicketBuilder'
import TicketPage from './TicketDetail'
import TicketList from './TicketList'

import ProjectNotification from './ProjectNotification'
import TestHOC from './HOC/TestHOC'
import Authorizations from './Authorizations'
import NewLogin from './NewLogin'
import ProjectLogs from './ProjectLogs'
import Utilities from './UtilitiesRoutes'
import ProjectViewer from './ProjectViewer'

import Badge from './Badge'

import {history} from './history'
// const history = createBrowserHistory()

const admins = ['admin', 'super-admin', 'client']
const inspectors = ['field-user', 'quality-manager']

const styles = {sidebar: {width: '18%'}, content: {flex: 80}, container: {flex: 1}}

export const Landing = () => <h1>Click the Sign In button on the left sidebar to get started</h1>

function Authorized({component: Component, ...rest}) {
  return <Route {...rest} render={(props) => <CheckRegistration {...{Component, ...rest, ...props}} />} />
}

function RedirectToUserManage() {
  return <Redirect to={`/users/${auth.sunkaizenUser.id}/manage`} />
}

export default function App() {
  // const [user, setUser] = useState(null)
  // useEffect(function () { return auth.subscribe(user => setUser(user)) }, [auth.currentUser])

  useEffect(() => {
    
    // db.collection('projects').where('status','==','Completed').get()
    // .then((querySnapshot) => {
    //   console.log('querySnapshot completed projects',querySnapshot.size)
    //   // querySnapshot.forEach((doc) => {
    //   //   console.log(doc.id, ' => ', doc.data());
    //   // });
    // }).catch(error => {
    //   console.log('error',error)
    // })
  })

  return (
    <> 
    <Badge/>
    <Router history={history}>
      
      <div className="app" style={{display: `flex`, flexFlow: `row nowrap`}}>
        <SystemNotifier />
        <div style={styles.sidebar}>
          <Route path="/" component={Sidebar} />
        </div>
        <div className="flex-column background-secondary" style={{width: `82%`, overflowY: 'scroll'}}>
          <div className="flex-colum align-start" style={styles.content}>
            <div className="full-height">
              <Switch>
                <Route path="/organization/:organization" component={Namespaced} />
                <Route path="/utilities" component={Utilities} />
                <Route exact path="/new_login/:email/:code" component={NewLogin} />
                <Route exact path="/landing" component={Landing} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/emailLogin" render={() => <EmailLogin auth={auth} />} />
                <Authorized exact path="/" component={Dashboard} />
                <Authorized exact path="/analytics" type={admins} component={Analytics} />
                <Authorized exact path="/schedule" type={[...admins, `scheduler`]} component={ScheduleInspections} />
                <Authorized exact path="/forms" type={admins} component={FormsList} />
                <Authorized exact path="/scoringSystems" type={admins} component={ScoringSystemsPage} />
                <Authorized exact path="/tickets" component={TicketList} />
                <Authorized exact path="/ticketModal" component={TicketModal} />
                <Authorized exact path="/projects" component={ProjectList} />
                <Authorized exact path="/users" type={admins} component={UserList} />
                <Authorized exact path="/authorizations" type={admins} component={Authorizations} />
                <Authorized exact path="/calendar" component={Calendar} />
                <Authorized exact path="/reports" component={Reports} type={[...admins, 'scheduler']} />
                <Authorized exact path="/field_users" type={admins} component={FieldUserDirectory} />
                <Authorized exact path="/installers" type={admins} component={InstallerDirectory} />
                <Authorized exact path="/autocompletetest" component={AutocompleteField} />
                <Authorized exact path="/testhoc" component={TestHOC} />
                <Authorized exact path="/pdftest" component={PdfTest} />
                <Authorized exact path="/testbed" component={Testbed} />
                <Authorized exact path="/project_viewer" types={['admin', 'super-admin']} component={ProjectViewer} />
                {/* <Authorized exact path='/newscheduleinspections' component={NewScheduleInspections} /> */}

                <Authorized exact path="/trash" component={GarbageProjectExporter} />
              </Switch>
              <Switch>
                <Route exact path="/settings/account" render={() => <RedirectToUserManage auth={auth} />} />
                <Authorized exact path="/tickets/:ticketId" component={TicketPage} />
                <Authorized exact path="/forms/:formId/rubrics" type={admins} component={Rubrics} />
                <Authorized exact path="/settings/system" type={admins} component={SystemSettings} />
                <Authorized exact path="/forms/:id" type={admins} component={FormEdit} />
                <Authorized exact path="/projectNotifications/:id" component={ProjectNotifications} />
                <Authorized exact path="/notifications/:id" component={ProjectNotification} />
                <Authorized exact path="/projects/:id/manage" component={DetailedProjectView} />
                <Authorized exact path="/users/:id/manage" component={DetailedUserView} />
                <Authorized exact path="/intake/:id" type={[...admins, ...inspectors]} component={DoIntake} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Router>
    </>
  )
}

function transform(path) {
  return `/organization/:organization/${path}`
}
function Namespaced({location}) {
  console.log(location)

  return (
    <div>
      <Switch>
        <Authorized exact path={transform('')} component={Dashboard} />
        <Authorized exact path={transform('analytics')} type={admins} component={Analytics} />
        <Authorized
          exact
          path={transform('schedule')}
          type={[...admins, `scheduler`, `field-user`]}
          component={ScheduleInspections}
        />
        <Authorized exact path={transform('forms')} type={admins} component={FormsList} />
        <Authorized exact path={transform('scoringSystems')} type={admins} component={ScoringSystemsPage} />
        <Authorized exact path={transform('tickets')} component={TicketList} />
        <Authorized exact path={transform('projects')} component={ProjectList} />
        <Authorized exact path={transform('projects_inspections')} component={ProjectInspectionsList} />
        <Authorized exact path={transform('projects_requests')} component={ProjectRequestsList} />
        <Authorized exact path={transform('users')} type={admins} component={UserList} />
        <Authorized exact path={transform('authorizations')} type={admins} component={Authorizations} />
        <Authorized exact path={transform('calendar')} component={Calendar} />
        <Authorized exact path={transform('reports')} component={Reports} type={[...admins, ...inspectors, 'scheduler']} />
        <Authorized exact path={transform('field_users')} type={admins} component={FieldUserDirectory} />
        <Authorized exact path={transform('installers')} type={admins} component={InstallerDirectory} />
      </Switch>
      <Authorized exact path={transform('settings/account')} component={RedirectToUserManage} />
      <Authorized exact path={transform('settings/system')} type={admins} component={SystemSettings} />
      <Authorized exact path={transform('tickets/:ticketId')} component={TicketPage} />
      <Authorized exact path={transform('forms/:formId/rubrics')} type={admins} component={Rubrics} />
      <Authorized exact path={transform('forms/:id')} type={admins} component={FormEdit} />
      <Authorized exact path={transform('projectNotifications/:id')} component={ProjectNotifications} />
      <Authorized exact path={transform('notifications/:id')} component={ProjectNotification} />
      <Authorized
        exact
        path={transform('projects/:id/manage')}
        shared={['client', 'field-user']}
        component={DetailedProjectView}
      />
      <Authorized exact path={transform('users/:id/manage')} component={DetailedUserView} />
      <Authorized
        exact
        path={transform('intake/:id')}
        shared={['field-user']}
        type={[...admins, ...inspectors]}
        component={DoIntake}
      />
      <Authorized exact path={transform('projects/:id/logs')} component={ProjectLogs} />
    </div>
  )
}
