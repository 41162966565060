import React, {useEffect, useState, useReducer} from 'react'
import UserInformation from './UserBuilder/UserInformation'
import {usersOne} from './UserBuilder/utilities'
import {loggerEffect, checkLogin} from './effects'
import {auth} from './Auth'

const styles = {
  button: {padding: '0.3em', width: '10rem', margin: '0.3em', border: 'none'},
}

function reducer(state, action) {
  switch (action.type) {
    case 'change':
      return {...state, [action.key]: action.value}
    default:
      return state
  }
}

export default function Register({history}) {
  const [state, dispatch] = useReducer(reducer, {type: 'client'})
  const [logger, setLogger] = useState(null)

  useEffect(
    checkLogin(() => history.push('/')),
    [auth.sunkaizenUser]
  )
  useEffect(loggerEffect(setLogger), [])

  async function submit() {
    try {
      await usersOne({...state, id: auth.currentUser.uid}, logger)
      logger.log('Registration Complete; logging in...')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="full-width">
      <div style={{padding: '3rem'}}>
        <h1>Register</h1>
        <h3>This login is not associated with an account on FIT-QM; please create one</h3>
        <div className="flex-row justify-center padding-top-2">
          <UserInformation
            noheader
            notype
            style={{width: '80%'}}
            fields={state}
            setField={(key) => (value) => dispatch({type: 'change', key, value})}
            autocomplete="new-password"
          />
        </div>
        <div
          style={styles.button}
          className="background-primary color-white flex-row justify-center bold pointer"
          onClick={submit}
        >
          Submit
        </div>
      </div>
    </div>
  )
}
