import React from 'react'
import {history} from '../history'

function LabelToFieldRow({label, fieldValue}) {
  return (
    <div className="flex-row justify-space-between align-center">
      <div className="subsection-title">{label}</div>
      <div className="light-text">{fieldValue}</div>
    </div>
  )
}

export default function DetailsBox({ticket}) {
  let assignedName
  if (ticket.watcherMap && ticket.watcherMap[ticket.assignee]) assignedName = ticket.watcherMap[ticket.assignee].name
  else if (ticket.otherName) assignedName = ticket.otherName
  else assignedName = `Unassigned`

  return (
    <div className="flex-column" style={{minWidth: '30rem'}} data-cy="ticketDetailCard">
      <div className="flex-row align-center justify-space-between">
        <h2 className="section-heading padding-0 margin-0">Details</h2>
        <div className={`status-tag status-${ticket.status && ticket.status.toLowerCase()} role-creator`}>
          {ticket.status}
        </div>
      </div>
      <div>
        <LabelToFieldRow label="Assignee" fieldValue={assignedName} />
        <LabelToFieldRow label="Created" fieldValue={ticket.created && ticket.created.toDate().toDateString()} />
        <LabelToFieldRow label="Created by" fieldValue={ticket.creatorMap && ticket.creatorMap.name} />
      </div>
      <div className="padding-top-2 margin-bottom-2">
        <div>Project</div>
        <a onClick={() => history.push(`/projects/${ticket.project}/manage`)}>
          <div>{ticket.site && ticket.site.geocode_results.formatted_address}</div>
          <div>inspected on {ticket.inspectionDate && ticket.inspectionDate.toDate().toDateString()}</div>
        </a>
      </div>
      <div className="margin-bottom-1">
        <div className="color-black">Watchers</div>
        {ticket.watchers &&
          ticket.watchers.map((watcher) => (
            <div key={watcher} className="light-text">
              {ticket.watcherMap && (ticket.watcherMap[watcher] || {}).name}
            </div>
          ))}
      </div>
    </div>
  )
}
