import {db} from '../../utilities'
import {makeSiteAddress, assembleProject} from '.'

export default async function projectsOne(fields) {
  const projRef = db.collection(`projects`).doc()
  const siteRef = db.collection(`sites`).doc()

  try {
    const {geocode_results = {}, latitude, longitude, restrict_schedule} = fields
    const {siteAddress = ``, dummyAddress = ``, sameRep = false, sameAddress = false} = fields
    const {siteOwnerName = ``, siteRepName = ``, siteRepEmail = ``, siteRepPhone = ``} = fields
    const {client, projectType, inspector, installer = {id: ``, name: ``}, rubric = null, organization, formRubrics} = fields

    let site = {
      geocode_results,
      siteAddress,
      sameAddress,
      siteRepName,
      siteRepEmail,
      siteRepPhone,
      sameRep,
      siteOwnerName,
      id: siteRef.id,
    }

    site = await makeSiteAddress({
      site,
      siteAddress,
      dummyAddress,
      latitude,
      longitude,
    })

    await siteRef.set(site)

    await assembleProject({
      organization,
      client,
      installer,
      site,
      projectType,
      inspector,
      rubric,
      projRef,
      restrict_schedule: !!restrict_schedule, // convert
      formRubrics
    })

    return projRef.id
  } catch (e) {
    console.log(`an error occurred while saving a single project to the database: `, e)
    throw e
  }
}
