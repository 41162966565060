import {store} from '../../utilities'
import {setFailures, setTicket} from '../../action_creators'
import {history} from '../../../history'
import {makeTicket} from '../../utilities'
import DB from '../../../DAL/DB'

const tickets = new DB.Tickets()

export default async function onOk() {
  const state = store.getState()
  const ff = state.failures

  try {
    const ticket = await makeTicket()

    console.log(ticket)

    await tickets.update(ticket.id, null, ticket)

    delete ff[ticket.failure.id]
    store.dispatch(setTicket({priority: 'Normal'}))
    store.dispatch(setFailures(ff))

    if (!Object.keys(ff).length && state.meta.active === 'create') {
      history.push('/tickets')
    }
  } catch (e) {
    console.log('ticket creation failed', e)
    throw e
  }
}
